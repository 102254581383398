@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.blur {
  overflow: hidden;
}

body.blur header {
  background-color: transparent;
}

body.blur #content > * {
  -webkit-filter: blur(5px) brightness(0.7);
          filter: blur(5px) brightness(0.7);
  /* transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1); */
  /* pointer-events: none; */
  /* user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

/* ------------------------------------------------------------------ */
/* Grid
--------------------------------------------------------------- */
.row {
  width: 96%;
  max-width: 1020px;
  margin: 0 auto;
}
/* fixed width for IE8 */
.ie .row {
  width: 1000px;
}

.narrow .row {
  max-width: 980px;
}

.row .row {
  width: auto;
  max-width: none;
  margin: 0 -20px;
}

/* row clearing */
.row:before,
.row:after {
  content: ' ';
  display: table;
}
.row:after {
  clear: both;
}

.column,
.columns {
  position: relative;
  padding: 0 20px;
  min-height: 1px;
  float: left;
}
.column.centered,
.columns.centered {
  float: none;
  margin: 0 auto;
}

/* removed gutters */
.row.collapsed > .column,
.row.collapsed > .columns,
.column.collapsed,
.columns.collapsed {
  padding: 0;
}

[class*='column'] + [class*='column']:last-child {
  float: right;
}
[class*='column'] + [class*='column'].end {
  float: right;
}

/* column widths */
.row .one {
  width: 8.33333%;
}
.row .two {
  width: 16.66667%;
}
.row .three {
  width: 25%;
}
.row .four {
  width: 33.33333%;
}
.row .five {
  width: 41.66667%;
}
.row .six {
  width: 50%;
}
.row .seven {
  width: 58.33333%;
}
.row .eight {
  width: 66.66667%;
}
.row .nine {
  width: 75%;
}
.row .ten {
  width: 83.33333%;
}
.row .eleven {
  width: 91.66667%;
}
.row .twelve {
  width: 100%;
}

/* Offsets */
.row .offset-1 {
  margin-left: 8.33333%;
}
.row .offset-2 {
  margin-left: 16.66667%;
}
.row .offset-3 {
  margin-left: 25%;
}
.row .offset-4 {
  margin-left: 33.33333%;
}
.row .offset-5 {
  margin-left: 41.66667%;
}
.row .offset-6 {
  margin-left: 50%;
}
.row .offset-7 {
  margin-left: 58.33333%;
}
.row .offset-8 {
  margin-left: 66.66667%;
}
.row .offset-9 {
  margin-left: 75%;
}
.row .offset-10 {
  margin-left: 83.33333%;
}
.row .offset-11 {
  margin-left: 91.66667%;
}

/* Push/Pull */
.row .push-1 {
  left: 8.33333%;
}
.row .pull-1 {
  right: 8.33333%;
}
.row .push-2 {
  left: 16.66667%;
}
.row .pull-2 {
  right: 16.66667%;
}
.row .push-3 {
  left: 25%;
}
.row .pull-3 {
  right: 25%;
}
.row .push-4 {
  left: 33.33333%;
}
.row .pull-4 {
  right: 33.33333%;
}
.row .push-5 {
  left: 41.66667%;
}
.row .pull-5 {
  right: 41.66667%;
}
.row .push-6 {
  left: 50%;
}
.row .pull-6 {
  right: 50%;
}
.row .push-7 {
  left: 58.33333%;
}
.row .pull-7 {
  right: 58.33333%;
}
.row .push-8 {
  left: 66.66667%;
}
.row .pull-8 {
  right: 66.66667%;
}
.row .push-9 {
  left: 75%;
}
.row .pull-9 {
  right: 75%;
}
.row .push-10 {
  left: 83.33333%;
}
.row .pull-10 {
  right: 83.33333%;
}
.row .push-11 {
  left: 91.66667%;
}
.row .pull-11 {
  right: 91.66667%;
}

/* block grids
--------------------------------------------------------------------- */
.bgrid-sixths [class*='column'] {
  width: 16.66667%;
}
.bgrid-quarters [class*='column'] {
  width: 25%;
}
.bgrid-thirds [class*='column'] {
  width: 33.33333%;
}
.bgrid-halves [class*='column'] {
  width: 50%;
}

[class*='bgrid'] [class*='column'] + [class*='column']:last-child {
  float: left;
}

/* Left clearing for block grid columns - columns that changes width in
different screen sizes. Allows columns with different heights to align
properly.
--------------------------------------------------------------------- */
.first {
  clear: left;
} /* first column in default screen */
.s-first {
  clear: none;
} /* first column in smaller screens */

/* smaller screens
--------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
  /* block grids on small screens */
  .s-bgrid-sixths [class*='column'] {
    width: 16.66667%;
  }
  .s-bgrid-quarters [class*='column'] {
    width: 25%;
  }
  .s-bgrid-thirds [class*='column'] {
    width: 33.33333%;
  }
  .s-bgrid-halves [class*='column'] {
    width: 50%;
  }

  /* block grids left clearing */
  .first {
    clear: none;
  }
  .s-first {
    clear: left;
  }
}

/* mobile wide/smaller tablets
--------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  .row {
    width: 460px;
    margin: 0 auto;
    padding: 0;
  }
  .column,
  .columns {
    width: auto !important;
    float: none;
    margin-left: 0;
    margin-right: 0;
    padding: 0 30px;
  }
  .row .row {
    width: auto;
    max-width: none;
    margin: 0 -30px;
  }

  [class*='column'] + [class*='column']:last-child {
    float: none;
  }
  [class*='bgrid'] [class*='column'] + [class*='column']:last-child {
    float: none;
  }

  /* Offsets */
  .row .offset-1 {
    margin-left: 0%;
  }
  .row .offset-2 {
    margin-left: 0%;
  }
  .row .offset-3 {
    margin-left: 0%;
  }
  .row .offset-4 {
    margin-left: 0%;
  }
  .row .offset-5 {
    margin-left: 0%;
  }
  .row .offset-6 {
    margin-left: 0%;
  }
  .row .offset-7 {
    margin-left: 0%;
  }
  .row .offset-8 {
    margin-left: 0%;
  }
  .row .offset-9 {
    margin-left: 0%;
  }
  .row .offset-10 {
    margin-left: 0%;
  }
  .row .offset-11 {
    margin-left: 0%;
  }
}

/* mobile narrow
--------------------------------------------------------------- */
@media only screen and (max-width: 460px) {
  .row {
    width: auto;
  }
}

/* larger screens
--------------------------------------------------------------- */
@media screen and (min-width: 1200px) {
  .wide .row {
    max-width: 1180px;
  }
}

/* import google font */

.App {
  /* background-color: ; */
  font-family: 'Raleway', sans-serif;
}

/* global styles */
a {
  display: inline-block;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#nav a:hover,
#nav a:focus,
#nav button:hover,
#nav button:focus {
  color: #fcdb81;
}

p {
  line-height: 30px;
  color: #ddd;
}

h2 {
  font-size: 35px;
  color: #fcdb81;
  /* color: #f4b705; */
  text-align: center;
}

h3 {
  font-size: 25px;
  /* color: #ddd; */
  /* color: #fcdb81; */
  /* color: #f4b705; */
  color: #bfefff;
  margin-bottom: 12px;
  text-align: center;
}

hr {
  width: 38.2%;
  margin-top: 1rem;
  /* margin: 18px auto 24px auto; */
  /* border-color: #2f2d2e; */
  /* border-color: rgba(150, 150, 150, 0.1); */
}

/* landing page style */
#home {
  position: relative;
  height: 100vh;
  width: 100%;
  /* background-color: rgb(35, 37, 39); */
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

/* make banner centered */
#home:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

/* div for banner */
#home .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

@media only screen and (min-width: 650px) {
  #home .banner-text h1 {
    font-size: 90px;
    color: #fcdb81;
    margin: 0 auto 18px auto;
    letter-spacing: 1px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  }
}

@media only screen and (max-width: 950px) {
  #home .banner-text h1 {
    font-size: 50px;
    color: #fcdb81;
    margin: 0 auto 18px auto;
    letter-spacing: 1px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  }
}

/* Hi line */

/* typing */
#home .banner-text h3 {
  font-size: 18px;
  color: #fff;
  margin: 0 auto;
  width: 100%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

#home .social {
  margin: 50px 24px;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}

#home .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

#home .social li a {
  color: #fff;
}

#home .social li a:hover {
  color: #11abb0;
}

#contact .social {
  /* margin: 50px 24px; */
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}

#contact .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

#contact .social li a {
  color: #fff;
}

#contact .social li a:hover {
  color: #11abb0;
}

/* about section */

@media only screen and (min-width: 650px) {
  #about .about-grid,
  #experience .experience-grid,
  #portfolio .portfolio-grid,
  #second-portfolio .portfolio-grid,
  #contact .contact-grid,
  #skill .skill-grid {
    width: 90%;
    margin: auto;
    height: 100%;
    vertical-align: middle;
    padding: 50px 50px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 950px) {
  #about .about-grid,
  #experience .experience-grid,
  #portfolio .portfolio-grid,
  #second-portfolio .portfolio-grid,
  #contact .contact-grid,
  #skill .skill-grid {
    width: 100%;
    margin: auto;
    height: 100%;
    vertical-align: middle;
    padding: 25px 25px;
    border: none;
    box-sizing: border-box;
  }
}

#about .profile-pic {
  /* position: relative; */
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#about td {
  line-height: 30px;
  color: #ddd;
}

#about li {
  color: #ddd;
  line-height: 30px;
}

#experience h4 {
  color: #ddd;
}

#experience h5 {
  color: #ddd;
  line-height: 20px;
}

