body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.blur {
  overflow: hidden;
}

body.blur header {
  background-color: transparent;
}

body.blur #content > * {
  filter: blur(5px) brightness(0.7);
  /* transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1); */
  /* pointer-events: none; */
  /* user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

/* ------------------------------------------------------------------ */
/* Grid
--------------------------------------------------------------- */
.row {
  width: 96%;
  max-width: 1020px;
  margin: 0 auto;
}
/* fixed width for IE8 */
.ie .row {
  width: 1000px;
}

.narrow .row {
  max-width: 980px;
}

.row .row {
  width: auto;
  max-width: none;
  margin: 0 -20px;
}

/* row clearing */
.row:before,
.row:after {
  content: ' ';
  display: table;
}
.row:after {
  clear: both;
}

.column,
.columns {
  position: relative;
  padding: 0 20px;
  min-height: 1px;
  float: left;
}
.column.centered,
.columns.centered {
  float: none;
  margin: 0 auto;
}

/* removed gutters */
.row.collapsed > .column,
.row.collapsed > .columns,
.column.collapsed,
.columns.collapsed {
  padding: 0;
}

[class*='column'] + [class*='column']:last-child {
  float: right;
}
[class*='column'] + [class*='column'].end {
  float: right;
}

/* column widths */
.row .one {
  width: 8.33333%;
}
.row .two {
  width: 16.66667%;
}
.row .three {
  width: 25%;
}
.row .four {
  width: 33.33333%;
}
.row .five {
  width: 41.66667%;
}
.row .six {
  width: 50%;
}
.row .seven {
  width: 58.33333%;
}
.row .eight {
  width: 66.66667%;
}
.row .nine {
  width: 75%;
}
.row .ten {
  width: 83.33333%;
}
.row .eleven {
  width: 91.66667%;
}
.row .twelve {
  width: 100%;
}

/* Offsets */
.row .offset-1 {
  margin-left: 8.33333%;
}
.row .offset-2 {
  margin-left: 16.66667%;
}
.row .offset-3 {
  margin-left: 25%;
}
.row .offset-4 {
  margin-left: 33.33333%;
}
.row .offset-5 {
  margin-left: 41.66667%;
}
.row .offset-6 {
  margin-left: 50%;
}
.row .offset-7 {
  margin-left: 58.33333%;
}
.row .offset-8 {
  margin-left: 66.66667%;
}
.row .offset-9 {
  margin-left: 75%;
}
.row .offset-10 {
  margin-left: 83.33333%;
}
.row .offset-11 {
  margin-left: 91.66667%;
}

/* Push/Pull */
.row .push-1 {
  left: 8.33333%;
}
.row .pull-1 {
  right: 8.33333%;
}
.row .push-2 {
  left: 16.66667%;
}
.row .pull-2 {
  right: 16.66667%;
}
.row .push-3 {
  left: 25%;
}
.row .pull-3 {
  right: 25%;
}
.row .push-4 {
  left: 33.33333%;
}
.row .pull-4 {
  right: 33.33333%;
}
.row .push-5 {
  left: 41.66667%;
}
.row .pull-5 {
  right: 41.66667%;
}
.row .push-6 {
  left: 50%;
}
.row .pull-6 {
  right: 50%;
}
.row .push-7 {
  left: 58.33333%;
}
.row .pull-7 {
  right: 58.33333%;
}
.row .push-8 {
  left: 66.66667%;
}
.row .pull-8 {
  right: 66.66667%;
}
.row .push-9 {
  left: 75%;
}
.row .pull-9 {
  right: 75%;
}
.row .push-10 {
  left: 83.33333%;
}
.row .pull-10 {
  right: 83.33333%;
}
.row .push-11 {
  left: 91.66667%;
}
.row .pull-11 {
  right: 91.66667%;
}

/* block grids
--------------------------------------------------------------------- */
.bgrid-sixths [class*='column'] {
  width: 16.66667%;
}
.bgrid-quarters [class*='column'] {
  width: 25%;
}
.bgrid-thirds [class*='column'] {
  width: 33.33333%;
}
.bgrid-halves [class*='column'] {
  width: 50%;
}

[class*='bgrid'] [class*='column'] + [class*='column']:last-child {
  float: left;
}

/* Left clearing for block grid columns - columns that changes width in
different screen sizes. Allows columns with different heights to align
properly.
--------------------------------------------------------------------- */
.first {
  clear: left;
} /* first column in default screen */
.s-first {
  clear: none;
} /* first column in smaller screens */

/* smaller screens
--------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
  /* block grids on small screens */
  .s-bgrid-sixths [class*='column'] {
    width: 16.66667%;
  }
  .s-bgrid-quarters [class*='column'] {
    width: 25%;
  }
  .s-bgrid-thirds [class*='column'] {
    width: 33.33333%;
  }
  .s-bgrid-halves [class*='column'] {
    width: 50%;
  }

  /* block grids left clearing */
  .first {
    clear: none;
  }
  .s-first {
    clear: left;
  }
}

/* mobile wide/smaller tablets
--------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  .row {
    width: 460px;
    margin: 0 auto;
    padding: 0;
  }
  .column,
  .columns {
    width: auto !important;
    float: none;
    margin-left: 0;
    margin-right: 0;
    padding: 0 30px;
  }
  .row .row {
    width: auto;
    max-width: none;
    margin: 0 -30px;
  }

  [class*='column'] + [class*='column']:last-child {
    float: none;
  }
  [class*='bgrid'] [class*='column'] + [class*='column']:last-child {
    float: none;
  }

  /* Offsets */
  .row .offset-1 {
    margin-left: 0%;
  }
  .row .offset-2 {
    margin-left: 0%;
  }
  .row .offset-3 {
    margin-left: 0%;
  }
  .row .offset-4 {
    margin-left: 0%;
  }
  .row .offset-5 {
    margin-left: 0%;
  }
  .row .offset-6 {
    margin-left: 0%;
  }
  .row .offset-7 {
    margin-left: 0%;
  }
  .row .offset-8 {
    margin-left: 0%;
  }
  .row .offset-9 {
    margin-left: 0%;
  }
  .row .offset-10 {
    margin-left: 0%;
  }
  .row .offset-11 {
    margin-left: 0%;
  }
}

/* mobile narrow
--------------------------------------------------------------- */
@media only screen and (max-width: 460px) {
  .row {
    width: auto;
  }
}

/* larger screens
--------------------------------------------------------------- */
@media screen and (min-width: 1200px) {
  .wide .row {
    max-width: 1180px;
  }
}
