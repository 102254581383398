/* import google font */
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

.App {
  /* background-color: ; */
  font-family: 'Raleway', sans-serif;
}

/* global styles */
a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#nav a:hover,
#nav a:focus,
#nav button:hover,
#nav button:focus {
  color: #fcdb81;
}

p {
  line-height: 30px;
  color: #ddd;
}

h2 {
  font-size: 35px;
  color: #fcdb81;
  /* color: #f4b705; */
  text-align: center;
}

h3 {
  font-size: 25px;
  /* color: #ddd; */
  /* color: #fcdb81; */
  /* color: #f4b705; */
  color: #bfefff;
  margin-bottom: 12px;
  text-align: center;
}

hr {
  width: 38.2%;
  margin-top: 1rem;
  /* margin: 18px auto 24px auto; */
  /* border-color: #2f2d2e; */
  /* border-color: rgba(150, 150, 150, 0.1); */
}

/* landing page style */
#home {
  position: relative;
  height: 100vh;
  width: 100%;
  /* background-color: rgb(35, 37, 39); */
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

/* make banner centered */
#home:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

/* div for banner */
#home .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

@media only screen and (min-width: 650px) {
  #home .banner-text h1 {
    font-size: 90px;
    color: #fcdb81;
    margin: 0 auto 18px auto;
    letter-spacing: 1px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  }
}

@media only screen and (max-width: 950px) {
  #home .banner-text h1 {
    font-size: 50px;
    color: #fcdb81;
    margin: 0 auto 18px auto;
    letter-spacing: 1px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  }
}

/* Hi line */

/* typing */
#home .banner-text h3 {
  font-size: 18px;
  color: #fff;
  margin: 0 auto;
  width: 100%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

#home .social {
  margin: 50px 24px;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}

#home .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

#home .social li a {
  color: #fff;
}

#home .social li a:hover {
  color: #11abb0;
}

#contact .social {
  /* margin: 50px 24px; */
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}

#contact .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

#contact .social li a {
  color: #fff;
}

#contact .social li a:hover {
  color: #11abb0;
}

/* about section */

@media only screen and (min-width: 650px) {
  #about .about-grid,
  #experience .experience-grid,
  #portfolio .portfolio-grid,
  #second-portfolio .portfolio-grid,
  #contact .contact-grid,
  #skill .skill-grid {
    width: 90%;
    margin: auto;
    height: 100%;
    vertical-align: middle;
    padding: 50px 50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 950px) {
  #about .about-grid,
  #experience .experience-grid,
  #portfolio .portfolio-grid,
  #second-portfolio .portfolio-grid,
  #contact .contact-grid,
  #skill .skill-grid {
    width: 100%;
    margin: auto;
    height: 100%;
    vertical-align: middle;
    padding: 25px 25px;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

#about .profile-pic {
  /* position: relative; */
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#about td {
  line-height: 30px;
  color: #ddd;
}

#about li {
  color: #ddd;
  line-height: 30px;
}

#experience h4 {
  color: #ddd;
}

#experience h5 {
  color: #ddd;
  line-height: 20px;
}
